import Button from '@/components/ui/buttons/Button';
import type { TransformedPost } from '@/utils/content/parsers/transformWPData';

interface Props {
  categories: Record<string, TransformedPost[]>;
  selectedCategory: string | null;
  onSelectCategory: (category: string) => void;
  alignment?: 'left' | 'center';
}

export default function Selector({ categories, selectedCategory, onSelectCategory, alignment = 'left' }: Props) {
  return (
    <div className='h-10 flex-nowrap whitespace-nowrap scrollbar-hide'>
      <div className={`flex gap-2 ${alignment === 'center' ? 'justify-center' : ''}`}>
        {Object.keys(categories).map((category) => (
          <Button
            key={category}
            onClick={() => onSelectCategory(category)}
            intent='secondary'
            className={`min-w-[120px] whitespace-nowrap px-6 flex-shrink-0 ${
              selectedCategory === category
                ? '!bg-white !border-neutral-950 !border-2'
                : '!bg-neutral-100 border-transparent'
            }`}
          >
            {category}
          </Button>
        ))}
      </div>
    </div>
  );
}

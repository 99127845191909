import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid';
import { useRef, useState } from 'react';
import Button from '@/components/ui/buttons/Button';
import ArticleFull from '@/components/ui/cards/articles/ArticleFull';
import ArticleHorizontal from '@/components/ui/cards/articles/ArticleHorizontal';
import type { TransformedPost } from '@/utils/content/parsers/transformWPData';

interface Props {
  posts: TransformedPost[];
  category: string;
  showMoreButton?: boolean;
}

export default function HorizontalView({ posts, category, showMoreButton }: Props) {
  const [currentIndex, setCurrentIndex] = useState(0);
  const sliderRef = useRef<HTMLDivElement>(null);
  const [firstPost, ...remainingPosts] = posts;
  const horizontalPosts = remainingPosts.slice(0, 3);

  const getSlideWidth = () => {
    const firstSlide = sliderRef.current?.children[0] as HTMLElement | undefined;
    if (!firstSlide) return 0;

    const gap = 24;
    return firstSlide.offsetWidth + gap;
  };

  return (
    <>
      {/* Mobile Layout */}
      <div className='grid grid-cols-1 gap-6 lg:hidden'>
        {firstPost && (
          <div className='col-span-1'>
            <ArticleFull {...firstPost} />
          </div>
        )}

        <div className='col-span-1 flex flex-col gap-6'>
          {horizontalPosts.map((post) => (
            <ArticleHorizontal key={post.slug} {...post} displayDescription={false} />
          ))}
        </div>
      </div>

      {/* Desktop Layout */}
      <div className='relative hidden lg:block'>
        <div className='w-full overflow-hidden scroll-smooth'>
          <div
            ref={sliderRef}
            className='flex space-x-6 transition-transform duration-300'
            style={{ transform: `translateX(-${currentIndex * getSlideWidth()}px)` }}
          >
            {posts.map((post) => (
              <div key={post.slug} className='w-[calc((100%-72px)/4)] flex-shrink-0'>
                <ArticleFull {...post} />
              </div>
            ))}
          </div>
        </div>
        <Button
          intent='navigationLight'
          size='navigation'
          className='z-5 absolute left-2 top-[9.75rem] -translate-y-full'
          onClick={() => setCurrentIndex((current) => (current <= 0 ? posts.length - 4 : current - 1))}
        >
          <ChevronLeftIcon className='h-8 w-8' />
        </Button>
        <Button
          intent='navigationLight'
          size='navigation'
          className='z-5 absolute right-2 top-[9.75rem] -translate-y-full'
          onClick={() => setCurrentIndex((current) => (current >= posts.length - 4 ? 0 : current + 1))}
        >
          <ChevronRightIcon className='h-8 w-8' />
        </Button>
      </div>

      {showMoreButton && (
        <div className='!mt-8 flex justify-center'>
          <a href={`/${category}`}>
            <Button intent='secondary' size='default' className='min-w-32 px-12'>
              More
            </Button>
          </a>
        </div>
      )}
    </>
  );
}

import { useEffect, useState } from 'react';

import type { TransformedPost } from '@/utils/content/parsers/transformWPData';

import ExpandedView from './MultiCategory/ExpandedView';
import HorizontalView from './MultiCategory/HorizontalView';
import Selector from './MultiCategory/Selector';

interface Props {
  categories: Record<string, TransformedPost[]>;
  expanded?: boolean;
  selectorAlignment?: 'left' | 'center';
}

export default function MultiCategory({ categories, expanded = false, selectorAlignment = 'left' }: Props) {
  const [selectedCategory, setSelectedCategory] = useState<string>(Object.keys(categories)[0]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const categoryCount = Object.keys(categories).length;

  // biome-ignore lint/correctness/useExhaustiveDependencies: We want to reset the page when the category changes
  useEffect(() => {
    setCurrentPage(1);
  }, [selectedCategory]);

  return (
    <div className='space-y-3'>
      {categoryCount > 1 && (
        <div className='relative w-full'>
          <div className='scrollbar-hide overflow-x-auto'>
            <div className={`inline-flex min-w-full ${selectorAlignment === 'center' ? 'justify-center' : ''}`}>
              <Selector
                categories={categories}
                selectedCategory={selectedCategory}
                onSelectCategory={setSelectedCategory}
                alignment={selectorAlignment}
              />
            </div>
          </div>
        </div>
      )}

      {selectedCategory &&
        (expanded ? (
          <ExpandedView
            posts={categories[selectedCategory]}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        ) : (
          <HorizontalView posts={categories[selectedCategory]} category={selectedCategory} showMoreButton={true} />
        ))}
    </div>
  );
}
